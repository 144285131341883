*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.tooltip{
  min-width: 350px;
  max-width: 350px;
}
.followedIcon{
  width: 24px;
  height: 24px;
  margin: 0 auto;
  cursor: pointer;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}