.top_list {
  width: 1440px;
  height: auto;
  padding: 30px;
  background-color: #FFFFFF;
  margin: 20px auto 20px auto;

  .ranking {
    img {
      width: 55px;
      height: 27px;
    }
  }

  .inline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .logo {
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      background-color: #FFFFFF;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .name {
      font-size: 16px;
      color: #333333;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .custom_txt {
    font-size: 20px;
    color: #666666;
  }

  .top_search {
    padding: 0 18px;

    .item_line {
      display: flex;
      flex-direction: row;
      margin-bottom: 11px;

      .title {
        min-width: 120px;
        max-width: 120px;
        font-size: 14px;
        color: #333333;
        margin-right: 13px;
      }

      .button_content {
        //width: calc(100% - 90px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
      }

      .dateblow {
        height: 29px;
        line-height: 29px;
        padding: 0 20px;
        font-size: 12px;
        text-align: center;
        color: #666666;
        margin-bottom: 13px;
      }

      .button {
        height: 29px;
        line-height: 29px;
        padding: 0 20px;
        font-size: 12px;
        color: #666666;
        border-radius: 8px;
        border: 1px solid #CCCCCC;
        width: 136px;
        text-align: center;
        margin-right: 13px;
      }

      .more {
        padding: 0;
        border: none;
        color: #38f;
      }

      .button_activity {
        background-color: #2C67A4;
        color: #FFFFFF;
      }
    }
  }

  .export {
    text-align: right;
  }

  .reset_button {
    padding: 0 15px;
    min-width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 17px;
    background-color: #F4791F;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    display: inline-block;

    .icon {
      margin-right: 6px;
    }
  }

  .export_button {
    margin: 0 15px 15px 0;
    padding: 0 15px;
    min-width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 17px;
    background-color: #F4791F;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    display: inline-block;

    .icon {
      margin-right: 6px;
    }
  }

  .cate_header {
    cursor: auto;
    position: relative;
    padding: 16px;

    .title_name {
      white-space: nowrap;

      .text_icon {
        margin-right: 4px;
      }
    }
  }

  .no_cursor {
    cursor: auto;
  }

  .compare {
    display: flex;
    justify-content: space-evenly;
    background-color: #2C67A4;
    color: #FFFFFF;
  }

  .total {
    font-size: 20px;
  }

  .total_number {
    color: #F4791F;
  }

  .Tooltip {
    background-color: #F4791F;
  }

  .ant-table-thead>tr>th {
    padding: 0 !important;
  }

  .right_compare {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .compareNumber {
      font-size: 15px;
      color: #e02a33;
    }

    .span {
      margin-left: 2px;
    }

    .upIcon {
      transform: rotate(180deg);
      color: #0ae653;
    }

    .upNumber {
      color: #0ae653;
    }
  }
}